import React, { memo } from 'react';
import { Provider } from 'react-redux';

import Routes from 'routes';
import { store } from 'store';

const App = memo(() => (
  <Provider store={store}>
    <Routes />
  </Provider>
));

export default App;
