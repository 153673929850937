import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { memo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from 'components';
import { routeConfig } from 'config';
import { LoginScreen } from 'screens';
import AppRoutes from './AppRoutes';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#3c77f1',
      main: '#2d5cbc',
      dark: '#234894',
      contrastText: '#fff'
    }
  }
});

export default memo(() => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter basename="admin">
      <Switch>
        <Route path={routeConfig.login} component={LoginScreen} />
        <PrivateRoute path={routeConfig.dashboard} component={AppRoutes} />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
));
