import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

// import { routeConfig } from 'config';
import { OfficialAccountScreen } from 'screens';

export default ({ match }: RouteComponentProps) => (
  <Switch>
    {/* <Route
      path={`${match.path}${routeConfig.accountSmartNews}`}
      exact={true}
      component={SmartNewsAccountScreen}
    /> */}
    <Route path={`${match.path}`} component={OfficialAccountScreen} />
  </Switch>
);
