import 'react-datepicker/dist/react-datepicker.css';

import { Paper } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { AccountReadPublic, IAccount, IMap, MapSharedNewest } from 'apis';
import { keysConfig } from 'config';
import {
  setAnalyticsDataAction,
  setAnalyticsLoadingAction
} from 'reducers/analytics';
import gapi, { gapiClientRequest, IResult } from 'utils/gapi';

const gaDic: { [key: string]: { shortName: string } } = {
  'ga:date': {
    shortName: 'date'
  },
  'ga:pagePath': { shortName: 'path' },
  'ga:pageviews': { shortName: 'views' },
  'ga:newUsers': { shortName: 'newUsers' },
  'ga:1dayUsers': { shortName: 'dau' },
  'ga:7dayUsers': { shortName: 'wau' },
  'ga:30dayUsers': { shortName: 'mau' }
};

const pageTitleMap: Record<string, string> = {
  '/faq.php': 'FAQ',
  '/news.php': 'ニュース',
  '/register': '新規登録',
  '/passwordReset': 'パスワード再設定',
  '/opinion.php': 'ご意見',
  '/ml3_facebook_redirect.php': 'Facebook ログイン',
  '/otaru-kankou.php': '小樽サイネージ',
  '/index.php': 'トップページ',
  '/naha-bus-station.index.php': '那覇バスターミナルトップ',
  '/naha-bus-station.php': '那覇バスターミナルトップ',
  '/signage/': 'サイネージ LP',
  '/inbound/': 'インバウンド LP',
  '/provisionalRegis.html': '新規登録',
  '/lp/': 'Map Life LP',
  '/multilinguallp/': '多言語 LP',
  '/createAccountConfirm.html': '新規登録',
  '/createAccount.html': '新規登録',
  '/forgotPassword.html': 'パスワード忘れた方',
  '/news/': 'ワードプレスブログ',
  '/links/universal.php': 'アプリインストールQRコード読み込み',
  '/provisionalRegist.html': '新規登録',
  '/official_maps.php': '公式マップ',
  '/popular_maps.php': '人気マップ',
  '/newest_maps.php': '新着マップ',
  '/index.html': 'トップ'
};
const oldPages = [
  '/function.html',
  '/printRegister.php',
  '/login.html',
  '/download_contents.html',
  '/higai.php',
  '/proxy',
  '/translate_c'
];
const pageTitleList = Object.keys(pageTitleMap);

const today = new Date();

export default memo(() => {
  const [view, setView] = useState('');

  const [from, setFrom] = useState(
    new Date(new Date().setDate(today.getDate() - 7))
  );
  const [to, setTo] = useState(
    new Date(new Date().setDate(today.getDate() - 1))
  );

  const dispatch = useDispatch();

  useEffect(() => {
    gapi.analytics.auth.authorize({
      container: 'embedApiAuthContainer',
      clientid: keysConfig.googleOAuthClientId
    });
    setTimeout(() => {
      const viewSelector = new gapi.analytics.ViewSelector({
        container: 'viewSelectorContainer'
      });
      viewSelector.execute();
      viewSelector.on('change', setView);
    }, 100);
  }, []);

  return (
    <LoginContainerPaper>
      <div id="embedApiAuthContainer" hidden />
      <ViewSelectorDiv id="viewSelectorContainer" />
      <DateContainerDiv>
        <DatePicker
          selected={from}
          selectsStart
          startDate={from}
          endDate={to}
          onChange={date => {
            if (date) {
              setFrom(date);
            }
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <span>から</span>
        <DatePicker
          selected={to}
          selectsEnd
          startDate={from}
          endDate={to}
          onChange={date => {
            if (date) {
              setTo(date);
            }
          }}
          minDate={from}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </DateContainerDiv>

      <Fab
        variant="extended"
        color="primary"
        disabled={!view}
        onClick={async () => {
          dispatch(setAnalyticsLoadingAction({ loading: true }));
          try {
            const viewId = view;
            const dateRanges = [
              {
                startDate: moment(from).format('YYYY-MM-DD'),
                endDate: moment(to).format('YYYY-MM-DD')
              }
            ];
            const pageSize = 100000;

            const { reports }: IResult = await gapiClientRequest({
              body: {
                reportRequests: [
                  {
                    viewId,
                    dateRanges,
                    metrics: [
                      {
                        expression: 'ga:pageviews'
                      }
                    ],
                    orderBys: [
                      { fieldName: 'ga:pageviews', sortOrder: 'DESCENDING' }
                    ],
                    dimensions: [{ name: 'ga:date' }, { name: 'ga:pagePath' }],
                    pageSize
                  }
                ]
              }
            });

            const {
              columnHeader: {
                dimensions,
                metricHeader: { metricHeaderEntries }
              },
              data: { rows = [] }
            } = reports[0];

            const mapIds: string[] = [];
            const profileIds: string[] = [];

            const parsedData = _.compact(
              rows.map(row => {
                const data = {
                  date: '',
                  path: '',
                  views: 0
                };

                dimensions.forEach((field, index) => {
                  if (gaDic[field]) {
                    data[gaDic[field].shortName] = row.dimensions[index];
                  }
                });

                metricHeaderEntries.forEach(({ name }, index) => {
                  if (gaDic[name]) {
                    data[gaDic[name].shortName] = parseInt(
                      row.metrics[0].values[index],
                      10
                    );
                  }
                });

                const { date, path, views } = data;

                if (!date) {
                  return undefined;
                }

                const url = new URL(`https://naha-bus-station.mplf.net${path}`);
                let page = url.pathname;
                const locale = url.searchParams.get('hl') || 'ja';
                const qr = url.searchParams.get('qr') || undefined;
                let pageLink = 'https://www.mplf.net';
                let mapId: string | undefined;
                let profileId: string | undefined;

                if (page.indexOf('/maps/archive/') > -1) {
                  mapId = page.replace('/maps/archive/', '').split('/')[0];
                  mapIds.push(mapId);
                  pageLink += `/maps/archive/${mapId}`;
                } else if (page === '/') {
                  page = 'トップページ';
                } else if (page.indexOf('/profile') > -1) {
                  page = 'プローフィールページ';
                  profileId = url.searchParams.get('profile') || undefined;
                  if (profileId) {
                    profileIds.push(profileId);
                    pageLink += `/profile/?profile=${profileId}`;
                  }
                } else if (page.indexOf('/search_maps.php') > -1) {
                  pageLink += page;
                  page = '【検索】';
                  const type = url.searchParams.get('type');
                  if (type === 'official') {
                    page += ' - 公式マップ';
                  } else if (type === 'popular') {
                    page += ' - 人気マップ';
                  }
                  const keyword = url.searchParams.get('keyword');
                  if (keyword) {
                    page += ` - キーワード: ${keyword}`;
                  }
                  const category = url.searchParams.get('category');
                  if (category) {
                    page += ` - カテゴリ: ${category}`;
                  }
                  const prefecture = url.searchParams.get('prefecture');
                  if (category) {
                    page += ` - 都道府県: ${prefecture}`;
                  }
                } else {
                  let pageIdentified = false;
                  for (const key in pageTitleList) {
                    if (pageTitleList.hasOwnProperty(key)) {
                      if (page.indexOf(pageTitleList[key]) > -1) {
                        page = pageTitleMap[pageTitleList[key]];
                        pageLink += pageTitleList[key];
                        pageIdentified = true;
                        break;
                      }
                    }
                  }

                  for (const oldPageTitle in oldPages) {
                    if (page.indexOf(oldPageTitle) > -1) {
                      page = '旧ページ';
                      pageIdentified = true;
                      break;
                    }
                  }

                  if (!pageIdentified) {
                    // eslint-disable-next-line no-console
                    console.log(`NEW PAGE : ${page}`);
                  }
                }

                const momentDate = moment(date, 'YYYYMMDD');

                return {
                  page,
                  locale,
                  qr,
                  pageLink,
                  mapId,
                  profileId,
                  views,
                  date: momentDate.format('YYYY-MM-DD'),
                  momentDate: momentDate
                };
              })
            );

            const maps = await memorizedMapSharedNewest(mapIds);
            const profiles = await memorizedAccountReadPublic(profileIds);

            dispatch(
              setAnalyticsDataAction(
                parsedData.map(({ page, mapId, profileId, ...rest }) => {
                  let newPage = page;

                  if (mapId) {
                    newPage = maps[mapId] ? maps[mapId].title : '非公開マップ';
                  }

                  if (profileId) {
                    newPage = profiles[profileId]
                      ? profiles[profileId].nickname
                      : 'プロフィール';
                  }

                  return {
                    page: newPage,
                    ...rest
                  };
                })
              )
            );
          } catch (error) {
            alert('エラーが発生しました。');
            // eslint-disable-next-line no-console
            console.log(error);
          }
          dispatch(setAnalyticsLoadingAction({ loading: false }));
        }}
      >
        <SearchIcon />
        検索
      </Fab>
    </LoginContainerPaper>
  );
});

// #region styles
const LoginContainerPaper = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  flex-direction: column;

  button {
    margin-left: 8px;
  }
`;
const ViewSelectorDiv = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  margin-bottom: 18px;

  table {
    flex: 1;
    padding: 0 10px;

    tr {
      display: flex;
      flex-direction: column;

      td:last-child {
        width: 100% !important;

        & > div {
          width: 100%;
        }
      }
    }
  }
`;
const DateContainerDiv = styled.div`
  margin-bottom: 18px;

  input {
    padding: 8px;
    border-radius: 5px;
  }

  span {
    display: inline-block;
    margin: 0 20px;
  }
`;
// #endregion

const memorizedMaps: { [_id: string]: IMap } = {};
const memorizedMapSharedNewest = async (idList: string[]) => {
  try {
    const ids = _.uniq(idList).filter(
      id => !memorizedMaps[id] && id.length === 24
    );

    if (ids.length) {
      const { data } = await MapSharedNewest({ ids });

      data.maps.forEach(map => {
        memorizedMaps[map._id] = map;
      });
    }
  } catch (error) {
    alert('マップ名取得に失敗しました');
  }

  return memorizedMaps;
};

const memorizedProfiles: { [_id: string]: IAccount } = {};
const memorizedAccountReadPublic = async (idList: string[]) => {
  try {
    const ids = _.uniq(idList).filter(
      id => !memorizedProfiles[id] && id.length === 24
    );

    if (ids.length) {
      const { data } = await AccountReadPublic({ ids });

      data.forEach(account => {
        memorizedProfiles[account._id] = account;
      });
    }
  } catch (error) {
    alert('プロフィール取得に失敗しました');
  }

  return memorizedProfiles;
};
