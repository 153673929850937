/* eslint-disable @typescript-eslint/camelcase */
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React, { CSSProperties, memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'react-use/lib/useDebounce';
import { WindowScroller } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import styled from 'styled-components/macro';

import {
  MapSharedNewest,
  PHPMapLifeSliderGET,
  PHPMapLifeSliderPOST
} from 'apis';
import { Snackbar } from 'components';
import { AppState } from 'reducers';
import {
  loadAllMapsAction,
  setInitialSelectedMaps,
  setSearchKeywordAction,
  toggleSelectMapAction
} from 'reducers/sliderMaps';

const MapCard = memo<{
  _id: AppState['sliderMaps']['list'][0];
  style?: CSSProperties;
}>(({ _id, style }) => {
  const dispatch = useDispatch();
  const {
    title,
    description,
    update_datetime: updateDatetime,
    account: { nickname },
    prefecture,
    categoriesString,
    image_id,
    selected
  } = useSelector(({ sliderMaps: { dic } }: AppState) => dic[_id]);

  return (
    <ListItem
      button
      onClick={() => dispatch(toggleSelectMapAction(_id))}
      style={{
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.13)',
        ...style
      }}
    >
      <MapCardDiv>
        <div
          style={{
            backgroundImage: `url(https://www.mljsv.com/thumbnail/2ffc7af154cd9c098f7aa6346981b8fc7c359395658d3c6668d6c8560d9c3d96/${image_id})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <div>
          <h4>{title}</h4>
          <h5>{description}</h5>
          <p>
            {moment
              .utc(updateDatetime, 'YYYY-MM-DD HH-mm-ss')
              .local()
              .format('YYYY年MM月DD HH:mm')}{' '}
            | {nickname}
          </p>
          <p>
            {prefecture} | {categoriesString}
          </p>
        </div>
        <div>
          {selected && (
            <CheckCircleIcon style={{ color: '#3bb51e' }} fontSize="large" />
          )}
        </div>
      </MapCardDiv>
    </ListItem>
  );
});

const Controller = memo(() => {
  const [sliderChanged, setSliderChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');

  const dispatch = useDispatch();
  const selectedList = useSelector(
    ({ sliderMaps: { selectedList } }: AppState) => selectedList
  );
  const initialSelectedList = useSelector(
    ({ sliderMaps: { initialSelectedList } }: AppState) => initialSelectedList
  );
  const dic = useSelector(({ sliderMaps: { dic } }: AppState) => dic);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useDebounce(
    () => {
      dispatch(setSearchKeywordAction(keyword));
    },
    500,
    [keyword]
  );

  useEffect(() => {
    try {
      if (selectedList.length) {
        selectedList.forEach(_id => {
          if (initialSelectedList.indexOf(_id) < 0) {
            // not exists;
            setSliderChanged(true);
            throw new Error();
          }
        });

        initialSelectedList.forEach(_id => {
          if (selectedList.indexOf(_id) < 0) {
            setSliderChanged(true);
            throw new Error();
          }
        });
      }

      setSliderChanged(false);
    } catch (error) {}
  }, [selectedList, initialSelectedList]);

  return (
    <>
      <Grid item style={{ width: '100%' }}>
        <Paper style={{ marginBottom: 16, padding: 16 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant="h5">スライダー変更</Typography>
            <Fab
              variant="extended"
              color="primary"
              style={{ marginLeft: 16 }}
              disabled={!sliderChanged || loading}
              onClick={async () => {
                const list = selectedList.map(_id => ({
                  _id,
                  image_id: dic[_id].image_id
                }));

                setLoading(true);
                try {
                  const { data } = await PHPMapLifeSliderPOST(list);
                  dispatch(setInitialSelectedMaps(data.map(({ _id }) => _id)));
                  setSnackbarOpen(true);
                } catch (error) {
                  alert('エラーが発生しました！');
                }
                setLoading(false);
              }}
            >
              <EditIcon />
              変更を保存
            </Fab>
          </div>

          <TextField
            value={keyword}
            onChange={({ target: { value } }) => setKeyword(value)}
            fullWidth
            label="検索"
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: 'white' }}
          />
        </Paper>
      </Grid>

      <Snackbar
        message="スライダーを更新しました。"
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
});

const SelectedMaps = memo(() => {
  const selectedList = useSelector(
    ({ sliderMaps: { selectedList } }: AppState) => selectedList
  );

  return (
    <Paper>
      {(() => selectedList.map(_id => <MapCard key={_id} _id={_id} />))()}
    </Paper>
  );
});

export default memo(() => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const list = useSelector(({ sliderMaps: { list } }: AppState) => list);

  // eslint-disable-next-line no-null/no-null
  const listRef = useRef<List>(null);
  const [scrollElement, setScrollElement] = useState<HTMLElement | undefined>();
  useEffect(() => {
    const scrollElm = document.getElementById('mainContent');
    if (scrollElm) {
      setScrollElement(scrollElm);
    }
  }, []);

  useEffect(() => {
    fetchMapsAndSelectedSliders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchMapsAndSelectedSliders = async () => {
    try {
      try {
        const { data } = await PHPMapLifeSliderGET();
        dispatch(setInitialSelectedMaps(data.map(({ _id }) => _id)));
      } catch (error) {}

      const {
        data: { maps }
      } = await MapSharedNewest();
      const mapDic: AppState['sliderMaps']['dic'] = {};
      maps.forEach(({ _id, ...rest }) => {
        mapDic[_id] = {
          _id,
          ...rest
        };
      });
      dispatch(loadAllMapsAction(mapDic));
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  if (error) {
    return (
      <Typography variant="h5" align="center" color="textSecondary">
        エラーが発生しました。
      </Typography>
    );
  }

  if (loading) {
    return (
      <div style={{ margin: '0 auto' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container spacing={1}>
      <Controller />

      <Grid item sm={6} md={6} xs={6} xl={6}>
        <Typography variant="h4" gutterBottom>
          選択中のマップ
        </Typography>

        <SelectedMaps />
      </Grid>

      <Grid item sm={6} md={6} xs={6} xl={6}>
        <Typography variant="h4" gutterBottom>
          マップ一覧
        </Typography>

        <WindowScroller
          scrollElement={scrollElement}
          onScroll={({ scrollTop }) => {
            if (listRef.current) {
              listRef.current.scrollTo(scrollTop);
            }
          }}
        >
          {() =>
            // eslint-disable-next-line no-null/no-null
            null
          }
        </WindowScroller>
        <Paper style={{ width: '100%' }}>
          <StyledList
            ref={listRef}
            // eslint-disable-next-line no-null/no-null
            height={100}
            itemCount={list.length}
            itemSize={171}
            width="100%"
            overscanCount={4}
          >
            {({ index, style }) => {
              const _id = list[index];
              return <MapCard key={_id} _id={_id} style={style} />;
            }}
          </StyledList>
        </Paper>
      </Grid>
    </Grid>
  );
});

// #region styles
const StyledList = styled(List)`
  height: 100% !important;
`;
const MapCardDiv = styled.div`
  width: 100%;
  display: flex;
  height: 138px;

  & > div:first-child {
    width: 220px;
    border-radius: 4px;
    margin-right: 16px;
    background: #aaa;
  }

  & > div:nth-child(2) {
    flex: 1;
    min-width: 0;

    & > * {
      margin: 0;
    }

    h4 {
      max-height: 58px;
      overflow: hidden;
      font-size: 20px;
      margin-bottom: 4px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    h5 {
      font-weight: normal;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-height: 20px;
      max-height: 40px;
      overflow: hidden;
      font-size: 14px;
      margin-bottom: 4px;
    }

    p {
      font-size: 12px;
      color: #888;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > div:nth-child(3) {
    width: 50px;
    padding-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
// #endregion
