import _ from 'lodash';
import { Reducer } from 'redux';
import { createAction, ExtractActions } from 'redux-actions-promise-wrapper';

import { IMap } from 'apis';

// =========================================================
// ACTIONS
export const setInitialSelectedMaps = createAction('SET_INITIAL_SELECTED_MAPS')<
  ISliderMaps['initialSelectedList']
>();
export const loadAllMapsAction = createAction('LOAD_ALL_MAPS')<
  ISliderMaps['dic']
>();
export const toggleSelectMapAction = createAction('TOGGLE_SELECT_MAP')<
  IMap['_id']
>();
export const setSearchKeywordAction = createAction('SET_SEARCH_KEYWORD')<
  ISliderMaps['searchKeyword']
>();

// =========================================================
// REDUCER
interface ISliderMaps {
  dic: {
    [key: string]: IMap & { selected?: boolean };
  };
  list: IMap['_id'][];
  selectedList: IMap['_id'][];
  initialSelectedList: IMap['_id'][];
  searchKeyword: string;
}
const initialState: ISliderMaps = {
  dic: {},
  list: [],
  selectedList: [],
  initialSelectedList: [],
  searchKeyword: ''
};

type Actions = ExtractActions<{
  setInitialSelectedMaps: typeof setInitialSelectedMaps;
  loadAllMapsAction: typeof loadAllMapsAction;
  selectMapAction: typeof toggleSelectMapAction;
  setSearchKeywordAction: typeof setSearchKeywordAction;
}>;
const sliderMapsReducer: Reducer<ISliderMaps, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case setInitialSelectedMaps.TRIGGER:
      return {
        ...state,
        selectedList: [...action.payload],
        initialSelectedList: [...action.payload]
      };
    case loadAllMapsAction.TRIGGER:
      state.initialSelectedList.forEach(_id => {
        action.payload[_id].selected = true;
      });

      return {
        ...state,
        dic: action.payload,
        list: Object.keys(action.payload)
      };
    case toggleSelectMapAction.TRIGGER: {
      const _id = action.payload;
      const map = {
        ...state.dic[_id]
      };
      map.selected = !map.selected;

      let selectedList = [...state.selectedList];
      if (map.selected) {
        selectedList = [...state.selectedList, _id];
      } else if (selectedList.indexOf(_id) > -1) {
        selectedList.splice(selectedList.indexOf(_id), 1);
      }

      return {
        ...state,
        dic: {
          ...state.dic,
          [_id]: map
        },
        selectedList
      };
    }
    case setSearchKeywordAction.TRIGGER: {
      const searchKeyword = action.payload;
      let list = state.list;

      if (state.searchKeyword !== searchKeyword) {
        if (searchKeyword) {
          list = _.filter(
            state.dic,
            ({
              title,
              description,
              categoriesString,
              account: { nickname },
              prefecture
            }) =>
              !!(
                title.indexOf(searchKeyword) >= 0 ||
                description.indexOf(searchKeyword) >= 0 ||
                categoriesString.indexOf(searchKeyword) >= 0 ||
                (nickname && nickname.indexOf(searchKeyword) >= 0) ||
                (prefecture && prefecture.indexOf(searchKeyword) >= 0)
              )
          ).map(({ _id }) => _id);
        } else {
          list = Object.keys(state.dic);
        }
      }

      return {
        ...state,
        list,
        searchKeyword
      };
    }
    default:
      return state;
  }
};

export default sliderMapsReducer;
