import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { AdminLogin } from 'apis';
import { Location } from 'history';
import Cookies from 'js-cookie';
import React, { memo, useState } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';

import { routeConfig } from 'config';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default memo<
  RouteComponentProps<undefined, StaticContext, undefined | { from: Location }>
>(({ location: { state } }) => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(!!Cookies.get('adminSession'));
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  if (redirect) {
    return (
      <Redirect to={state && state.from ? state.from : routeConfig.dashboard} />
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Map Life アドミン
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            value={account}
            onChange={({ target: { value } }) => setAccount(value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="account"
            label="ID"
            name="account"
            autoComplete="account"
            autoFocus
            disabled={loading}
          />
          <TextField
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={loading}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            onClick={async e => {
              e.preventDefault();
              setLoading(true);
              try {
                const { session } = await AdminLogin({ account, password });
                Cookies.set('adminSession', session);
                setRedirect(true);
              } catch (error) {
                setLoading(false);
              }
            }}
          >
            ログイン
          </Button>
        </form>
      </div>
    </Container>
  );
});
