import IconButton from '@material-ui/core/IconButton';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import React, { memo } from 'react';

export default memo<
  { message: string; onClose?: () => void } & Pick<
    SnackbarProps,
    'open' | 'autoHideDuration'
  >
>(({ message, open, onClose, autoHideDuration }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    open={open}
    autoHideDuration={autoHideDuration}
    onClose={(e, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      if (onClose) {
        onClose();
      }
    }}
  >
    <SnackbarContent
      style={{ backgroundColor: '#43a047' }}
      message={message}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={e => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  </Snackbar>
));
