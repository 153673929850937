import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import analytics from './analytics';
import sliderMaps from './sliderMaps';

const reducers = combineReducers({
  analytics,
  sliderMaps
});

export type AppState = ReturnType<typeof reducers>;

// saga
export const rootSaga = function*() {
  yield all([]);
};

export default reducers;
