import _ from 'lodash';
import moment from 'moment';
import { Reducer } from 'redux';
import { createAction, ExtractActions } from 'redux-actions-promise-wrapper';

// =========================================================
// ACTIONS
export const setAnalyticsLoadingAction = createAction(
  'SET_ANALYTICS_LOADING_ACTION'
)<{ loading: IAnalytics['loading'] }>();

export const setAnalyticsDataAction = createAction('SET_ANALYTICS_DATA_ACTION')<
  IAnalytics['dic'][0]['list']
>();

export const setAnalyticsLocaleDicAction = createAction(
  'SET_ANALYTICS_LOCALE_DIC_ACTION'
)<IAnalytics['localeDic']>();

// =========================================================
// REDUCER
interface IAnalytics {
  loading: boolean;
  dic: {
    [date: string]: {
      list: {
        page: string;
        locale: string;
        qr?: string;
        pageLink: string;
        views: number;
        date: string;
        momentDate: moment.Moment;
      }[];
      totalViews: number;
      date: string;
      viewsByLocale: {
        [locale: string]: number;
      };
    };
  };
  localeDic: { [locale: string]: string };
}
const initialState: IAnalytics = {
  loading: false,
  dic: {},
  localeDic: {}
};

type Actions = ExtractActions<{
  setAnalyticsLoadingAction: typeof setAnalyticsLoadingAction;
  setAnalyticsDataAction: typeof setAnalyticsDataAction;
  setAnalyticsLocaleDicAction: typeof setAnalyticsLocaleDicAction;
}>;
const analyticsReducer: Reducer<IAnalytics, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case setAnalyticsLoadingAction.TRIGGER:
      return {
        ...state,
        loading: action.payload.loading
      };
    case setAnalyticsDataAction.TRIGGER: {
      return {
        ...state,
        loading: false,
        dic: _.sortBy(action.payload, 'date').reduce<IAnalytics['dic']>(
          (dic, data) => {
            const { date } = data;
            dic[date] = dic[date] || {
              list: [],
              date,
              totalViews: 0,
              viewsByLocale: {}
            };
            dic[date].list.push(data);
            dic[date].totalViews += data.views;

            dic[date].viewsByLocale[data.locale] =
              dic[date].viewsByLocale[data.locale] || 0;
            dic[date].viewsByLocale[data.locale] += data.views;

            return dic;
          },
          {}
        )
      };
    }
    case setAnalyticsLocaleDicAction.TRIGGER:
      return {
        ...state,
        localeDic: action.payload
      };
    default:
      return state;
  }
};

export default analyticsReducer;
