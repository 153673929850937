import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  AdminAccountAddOfficial,
  AdminAccountOfficials,
  IOfficialAccount
} from 'apis';
import { Snackbar } from 'components';
import { routeConfig } from 'config';

const AddOfficialAccountModal = memo<{
  open: boolean;
  requestClose: () => void;
  reloadList: () => void;
}>(({ open, requestClose, reloadList }) => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState('');
  const [nickname, setNickname] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setAccount('');
    setNickname('');
    setMail('');
    setPassword('');
    setConfirmPassword('');
  }, [open]);

  return (
    <>
      <Modal disableBackdropClick={true} open={open}>
        <Paper
          style={{
            width: 700,
            maxWidth: '100%',
            margin: '150px auto 0',
            padding: 16
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            公式アカウント追加
          </Typography>
          <TextField
            value={account}
            onChange={({ target: { value } }) => setAccount(value)}
            required
            fullWidth
            label="アカウントID"
            helperText="半角英数字4文字以上10文字まで"
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            value={nickname}
            onChange={({ target: { value } }) => setNickname(value)}
            required
            fullWidth
            label="ニックネーム"
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            value={mail}
            onChange={({ target: { value } }) => setMail(value)}
            required
            fullWidth
            label="メール"
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <TextField
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            required
            fullWidth
            label="パスワード"
            helperText="半角英数字混在8文字以上16文字まで"
            margin="normal"
            variant="outlined"
            type="password"
            disabled={loading}
          />
          <TextField
            value={confirmPassword}
            onChange={({ target: { value } }) => setConfirmPassword(value)}
            required
            fullWidth
            label="パスワード確認"
            margin="normal"
            variant="outlined"
            type="password"
            disabled={loading}
          />
          <TextField
            value={introduction}
            onChange={({ target: { value } }) => setIntroduction(value)}
            fullWidth
            multiline
            rows={4}
            rowsMax={4}
            label="コメント"
            margin="normal"
            variant="outlined"
            disabled={loading}
          />
          <Button
            variant="contained"
            color="default"
            style={{ margin: 8 }}
            onClick={() => requestClose()}
            disabled={loading}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 8 }}
            disabled={loading}
            onClick={async () => {
              try {
                setLoading(true);
                await AdminAccountAddOfficial({
                  account,
                  nickname,
                  introduction,
                  mail,
                  password,
                  confirmPassword
                });
                await reloadList();
                requestClose();
                setSnackbarOpen(true);
              } catch (error) {}
              setLoading(false);
            }}
          >
            追加
          </Button>
        </Paper>
      </Modal>

      <Snackbar
        message="公式アカウントを追加しました。"
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
});

export default memo<RouteComponentProps>(({ history }) => {
  const [officialAccounts, setOfficialAccounts] = useState<IOfficialAccount[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState('');

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getOfficialAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOfficialAccounts = async () => {
    try {
      setLoading(true);
      const { data } = await AdminAccountOfficials();
      setOfficialAccounts(data);
      setLoading(false);
    } catch (error) {
      Cookies.remove('adminSession');
      history.push(routeConfig.login);
    }
  };

  return (
    <>
      <AddOfficialAccountModal
        open={modalOpen}
        requestClose={() => setModalOpen(false)}
        reloadList={getOfficialAccounts}
      />

      <Grid item xs={12}>
        <Paper style={{ marginBottom: 16, padding: 16 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant="h5">公式アカウント管理</Typography>
            <Fab
              variant="extended"
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={() => setModalOpen(true)}
            >
              <AddIcon />
              公式アカウント追加
            </Fab>
          </div>

          <TextField
            value={keyword}
            onChange={({ target: { value } }) => setKeyword(value)}
            fullWidth
            label="検索"
            margin="normal"
            variant="outlined"
            style={{ backgroundColor: 'white' }}
          />
        </Paper>

        <Typography variant="h5" gutterBottom>
          公式アカウント一覧
        </Typography>

        <Typography variant="subtitle2" gutterBottom color="textSecondary">
          公式アカウントの編集は Map Life
          の方で編集したい公式アカウントでログインしてから編集してください
        </Typography>

        <Paper>
          <List>
            {(() =>
              officialAccounts
                .filter(
                  ({ nickname = '', account, mail }) =>
                    keyword === '' ||
                    nickname.indexOf(keyword) > -1 ||
                    account.indexOf(keyword) > -1 ||
                    mail.indexOf(keyword) > -1
                )
                .map(
                  ({
                    _id,
                    account,
                    nickname,
                    mail,
                    icon_url: iconUrl,
                    registration_datetime: registerAt
                  }) => (
                    <ListItem
                      button
                      key={_id}
                      component="a"
                      href={`/profile/?profile=${_id}`}
                      target="_blank"
                      style={{
                        borderBottom: '1px solid rgba(0,0,0,0.13)'
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={
                            iconUrl &&
                            `https://www.mljsv.com/thumbnail/2ffc7af154cd9c098f7aa6346981b8fc7c359395658d3c6668d6c8560d9c3d96/${iconUrl}`
                          }
                        >
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={nickname}
                        secondary={
                          <span>
                            {account}
                            <br />
                            {mail}
                            <br />
                            登録日:
                            {moment
                              .utc(registerAt, 'YYYY-MM-DD HH-mm-ss')
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </span>
                        }
                      />
                    </ListItem>
                  )
                ))()}
          </List>

          {loading && (
            <div style={{ padding: 8, textAlign: 'center' }}>
              <CircularProgress />
            </div>
          )}
        </Paper>
      </Grid>
    </>
  );
});
