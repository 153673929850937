import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { AppState } from 'reducers';

export default memo<{ setCopyText: (copyText: string) => void }>(
  ({ setCopyText }) => {
    let text = '';

    useEffect(() => {
      setCopyText(text);
    });

    const dic = useSelector(({ analytics: { dic } }: AppState) => dic);
    const localeDic = useSelector(
      ({ analytics: { localeDic } }: AppState) => localeDic
    );

    if (_.isEmpty(dic)) {
      return <></>;
    }

    return (
      <div>
        <Paper>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell>日付</TableCell>
                {_.map(localeDic, (label, locale) => (
                  <TableCell key={locale}>{label}</TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {Object.keys(dic).map((dayKey, i) => {
                const { date, viewsByLocale } = dic[dayKey];
                text += i ? `\n${date}` : `${date}`;
                return (
                  <TableRow key={date}>
                    <TableCell>{date}</TableCell>
                    {_.map(localeDic, (label, locale) => {
                      const views = viewsByLocale[locale] || 0;
                      text += `\t${views}`;
                      return <TableCell key={locale}>{views}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
);

const StyledTableHead = styled(TableHead)`
  background: #555;
  th {
    color: #ddd !important;
    font-weight: bold;
  }
`;
// #endregion
