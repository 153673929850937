import React, { memo } from 'react';

export default memo(() => <div />);

// import { Grid } from '@material-ui/core';
// import React, { memo, useEffect, useState } from 'react';
// import {
//   AutoSizer,
//   CellMeasurer,
//   CellMeasurerCache,
//   List,
//   WindowScroller
// } from 'react-virtualized';

// const cache = new CellMeasurerCache({
//   fixedWidth: true,
//   minHeight: 1
// });

// export default memo(() => {
//   const [scrollElement, setScrollElement] = useState<HTMLElement | undefined>();
//   useEffect(() => {
//     const scrollElm = document.getElementById('mainContent');
//     if (scrollElm) {
//       setScrollElement(scrollElm);
//     }
//   }, []);

//   return (
//     <Grid xl={12}>
//       <div>
//         <h1>Example react-virtualized dynamic height list</h1>
//       </div>
//       <div style={{ display: 'flex' }}>
//         <div style={{ flex: 1 }}>
//           <WindowScroller scrollElement={scrollElement}>
//             {({ scrollTop, height, isScrolling }) => (
//               <AutoSizer>
//                 {({ width }) => (
//                   <List
//                     // eslint-disable-next-line no-null/no-null
//                     tabIndex={null}
//                     autoHeight
//                     height={height}
//                     isScrolling={isScrolling}
//                     rowCount={1000}
//                     rowHeight={cache.rowHeight}
//                     deferredMeasurementCache={cache}
//                     rowRenderer={({ index, key, parent, style }) => {
//                       const source = `https://fillmurray.com/300/${index + 1}`;

//                       return (
//                         <CellMeasurer
//                           cache={cache}
//                           columnIndex={0}
//                           key={key}
//                           rowIndex={index}
//                           parent={parent}
//                         >
//                           {({ measure }) => (
//                             <div style={style}>
//                               <img
//                                 onLoad={measure}
//                                 src={source}
//                                 style={{
//                                   width: 300
//                                 }}
//                                 alt={String(index)}
//                               />
//                               - {index}
//                             </div>
//                           )}
//                         </CellMeasurer>
//                       );
//                     }}
//                     scrollTop={scrollTop}
//                     width={width}
//                   />
//                 )}
//               </AutoSizer>
//             )}
//           </WindowScroller>
//         </div>
//         <div style={{ flex: 1 }}>
//           <div>
//             <h1>H1</h1>
//             <h2>H2</h2>
//             <h3>H3</h3>
//             <h1>H1</h1>
//             <h2>H2</h2>
//             <h3>H3</h3>
//             <h1>H1</h1>
//             <h2>H2</h2>
//             <h3>H3</h3>
//             <p>Hello testing</p>
//           </div>
//         </div>
//       </div>
//     </Grid>
//   );
// });
