/* eslint-disable @typescript-eslint/camelcase */
import Axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import qs from 'qs';

import mplfConfig from 'config/mplfConfig.json';
import { AppState } from 'reducers';

const NODE_API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? 'https://maplife-api.icd-vietnam.com'
    : 'https://maplife-api.icd-vietnam.com';
const MPLF_API_ENDPOINT =
  process.env.NODE_ENV === 'production' ? '' : 'https://maplife.icd-vietnam.com';

const AxiosPost = async <T extends unknown = unknown>(
  url: string,
  data?: object,
  config?: AxiosRequestConfig
) =>
  Axios.post<T>(url, data, {
    headers: {
      'Content-Type': 'application/json'
    },
    ...config
  });

/**
 * If response status is 401, do logout
 */
const AxiosAuthPost = async <T extends unknown = unknown>(
  url: string,
  data: object
) => {
  try {
    const result = await AxiosPost<T>(url, data);
    return result;
  } catch (error) {
    if (error.response.data.status === 401) {
      alert('セッションが切れました');
      // Cookies.remove('adminSession');
      const href = window.location.href;
      window.location.href = href;
      return;
    }

    throw error;
  }
};

const throwAlert = (message: string) => {
  alert(message);
  throw new Error();
};

// ADMIN ============================================================================================================================

export const AdminLogin = async ({
  account,
  password
}: {
  account: string;
  password: string;
}) => {
  if (!account) {
    throwAlert('ID を入力してください！');
  }
  if (!password) {
    throwAlert('パスワード入力してください！');
  }

  try {
    const { data } = await AxiosPost<{ session: string }>(
      `${NODE_API_ENDPOINT}/ADMIN/LOGIN`,
      {
        account,
        password
      }
    );
    return data;
  } catch (error) {
    return throwAlert('IDまたは、パスワードが間違っております。');
  }
};

export interface IOfficialAccount {
  _id: string;
  account: string;
  mail: string;
  nickname?: string;
  icon_url?: string;
  registration_datetime: string;
  groups: string;
}
export const AdminAccountOfficials = () =>
  AxiosPost<IOfficialAccount[]>(
    `${NODE_API_ENDPOINT}/ADMIN/ACCOUNT/OFFICIALS`,
    {
      session: Cookies.get('adminSession')
    }
  );

export const AdminAccountAddOfficial = async ({
  account,
  nickname,
  introduction,
  mail,
  password,
  confirmPassword
}: {
  account: string;
  nickname: string;
  introduction?: string;
  mail: string;
  password: string;
  confirmPassword: string;
}) => {
  if (!account || !account.match(/^[a-zA-Z0-9]{4,10}$/)) {
    throwAlert('アカウントを正しく入力してください');
  }
  if (!nickname) {
    throwAlert('ニックネームを入力してください');
  }
  if (!mail || !mail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
    throwAlert('メールアドレスを正しく入力してください');
  }
  if (
    !password ||
    !password.match(/^(?=.*\d+)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,16}$/)
  ) {
    throwAlert('パスワードを正しく入力してください');
  }
  if (password !== confirmPassword) {
    throwAlert('パスワードとパスワード確認が一致していません');
  }

  try {
    await AxiosAuthPost(`${NODE_API_ENDPOINT}/ADMIN/ACCOUNT/ADD_OFFICIAL`, {
      session: Cookies.get('adminSession'),
      account,
      nickname,
      introduction,
      mail,
      password
    });
  } catch (error) {
    if (error.response && error.response.data) {
      switch (error.response.data.message) {
        case 'account':
          return throwAlert('このアカウントIDは使用できません。');
        case 'mail':
          return throwAlert('このメールは使用できません。');
        default:
          break;
      }
    }

    throwAlert('エラーが発せしました。');
  }
  return;
};

// PHP ============================================================================================================================

type SelectedMaps = Pick<IMap, '_id' | 'image_id'>[];
export const PHPMapLifeSliderGET = async () =>
  Axios.get<SelectedMaps>(
    `${MPLF_API_ENDPOINT}/resources/php/maplifeSlider.php`
  );

export const PHPMapLifeSliderPOST = (data: SelectedMaps) =>
  Axios.post<SelectedMaps>(
    `${MPLF_API_ENDPOINT}/resources/php/maplifeSlider.php`,
    qs.stringify({
      session: Cookies.get('adminSession'),
      data
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );

// MAP ============================================================================================================================

export interface IMap {
  _id: string;
  title: string;
  description: string;
  template_id?: string;
  share_id: string;
  image_id: string;
  update_datetime: string;
  account_id: string;
  categories: {
    lcategory: string;
  }[];
  categoriesString: string;
  account: {
    _id: string;
    icon_url?: string;
    nickname?: string;
  };
  prefecture?: string;
  special_contents?: string[];
}

export const MapSharedNewest = async ({ ids }: { ids?: string[] } = {}) => {
  const result = await AxiosPost<{ maps: IMap[] }>(
    `${NODE_API_ENDPOINT}/MAP/SHARED/NEWEST`,
    { ids, not_special_contents: ids ? undefined : ['hide-in-map-life'] }
  );

  result.data.maps = result.data.maps
    .filter(({ image_id }) => !!image_id)
    .map(({ categories, ...rest }) => ({
      ...rest,
      categories,
      categoriesString: categories.map(({ lcategory }) => lcategory).join('、')
    }));

  return result;
};

// ACCOUNT ============================================================================================================================

export interface IAccount {
  _id: string;
  update_datetime: string;
  nickname: string;
  icon_url: string;
  image_id: string;
  homepage: string;
  introduction: string;
  session_account_id: string;
}

export const AccountReadPublic = async ({ ids }: { ids: string[] }) =>
  AxiosPost<[IAccount]>(`${NODE_API_ENDPOINT}/ACCOUNT/READ_PUBLIC`, {
    _ids: ids
  });

// CONFIG ============================================================================================================================

type mplfConfig = typeof mplfConfig;
export const GetMplfConfig = async () => {
  let config: mplfConfig;
  try {
    const { data } = await Axios.get<mplfConfig>(
      `${MPLF_API_ENDPOINT}/js/config/config.json`
    );
    config = data;
  } catch (error) {
    config = mplfConfig;
  }

  const {
    active_settings: {
      localeSettings: { activeLocales }
    }
  } = config;

  const localeDic: AppState['analytics']['localeDic'] = {};

  _.forEach(activeLocales, (val, key) => {
    localeDic[key] = val.labelJapanese;
  });

  return localeDic;
};
