import Cookies from 'js-cookie';
import React, { memo } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { routeConfig } from 'config';

export default memo<RouteProps>(({ path, component, location, ...rest }) => (
  <Route
    path={path}
    component={
      Cookies.get('adminSession')
        ? component
        : () => (
            <Redirect
              to={{
                pathname: routeConfig.login,
                state: {
                  from: location
                }
              }}
            />
          )
    }
    {...rest}
  />
));
