import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ForwardIcon from '@material-ui/icons/Forward';
import MenuIcon from '@material-ui/icons/Menu';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import React, { memo, useState } from 'react';
import { NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { routeConfig } from 'config';
import { AnalyticsScreen, DashboardScreen, EditSliderScreen } from 'screens';
import AccountRoutes from './AccountRoutes';

export default memo<RouteComponentProps>(({ history }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(true);
            }}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Map Life アドミン
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => {
              Cookies.remove('adminSession');
              history.push(routeConfig.login);
            }}
          >
            <ForwardIcon />
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              ログアウト
            </Typography>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <NavLink to={routeConfig.dashboard} className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="ダッシュボード" />
              </ListItem>
            </NavLink>
          </div>
        </List>
        <Divider />
        <List>
          <ListSubheader inset>Map Life ポータル</ListSubheader>
          <div>
            <NavLink to={routeConfig.editSlider} className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <ViewCarouselIcon />
                </ListItemIcon>
                <ListItemText primary="スライダー変更" />
              </ListItem>
            </NavLink>
          </div>
        </List>
        <Divider />
        <List>
          <ListSubheader inset>アカウント関連</ListSubheader>
          <div>
            <NavLink to={routeConfig.account} className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary="公式アカウント管理" />
              </ListItem>
            </NavLink>
          </div>
          {/* <div>
            <NavLink
              to={`${routeConfig.account}${routeConfig.accountSmartNews}`}
              className={classes.link}
            >
              <ListItem button>
                <ListItemIcon>
                  <ChromeReaderModeIcon />
                </ListItemIcon>
                <ListItemText primary="SmartNewsアカウント" />
              </ListItem>
            </NavLink>
          </div> */}
        </List>
        <Divider />
        <List>
          <ListSubheader inset>GA データー集計</ListSubheader>
          <div>
            <NavLink to={routeConfig.analytics} className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="データー集計" />
              </ListItem>
            </NavLink>
          </div>
        </List>
      </Drawer>

      <main id="mainContent" className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Switch>
              <Route path={routeConfig.account} component={AccountRoutes} />
              <Route
                path={routeConfig.editSlider}
                component={EditSliderScreen}
              />
              <Route path={routeConfig.analytics} component={AnalyticsScreen} />
              <Route path={routeConfig.dashboard} component={DashboardScreen} />
            </Switch>
          </Grid>
        </Container>
      </main>
    </div>
  );
});

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));
