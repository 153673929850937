import { keysConfig } from 'config';
import gapi from './middlewareGAPI';

export const init = () =>
  new Promise((resolve, reject) => {
    gapi.load('auth2,analytics', () => {
      const result = gapi.auth2.init({
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: keysConfig.googleOAuthClientId,
        scope: 'https://www.googleapis.com/auth/analytics.readonly'
      });

      result.then(
        () => {
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  });

// promise wrapper
export const gapiClientRequest = async (requestOptions: requestOptions) => {
  let nextPageToken: string | undefined = '';
  const result = await innerGAPIClientRequest(requestOptions);
  nextPageToken = result.reports[0].nextPageToken;
  while (nextPageToken) {
    const nextResult = await innerGAPIClientRequest({
      ...requestOptions,
      body: {
        reportRequests: [
          {
            ...requestOptions.body.reportRequests[0],
            pageToken: nextPageToken
          }
        ]
      }
    });
    nextPageToken = nextResult.reports[0].nextPageToken;
    result.reports[0].data.rows = [
      ...(result.reports[0].data.rows || []),
      ...(nextResult.reports[0].data.rows || [])
    ];
  }

  return result;
};

// check has next page token or not
const innerGAPIClientRequest = (requestOptions: requestOptions) =>
  new Promise<IResult>((resolve, reject) => {
    gapi.client
      .request({
        path: '/v4/reports:batchGet',
        // @ts-ignore
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        ...requestOptions
      })
      .then(
        ({ result }: { result: IResult }) => {
          resolve(result);
        },
        (error: Error) => {
          reject(error);
        }
      );
  });

export default gapi;

type requestOptions = Omit<
  Parameters<typeof gapi['client']['request']>[0],
  'path' | 'root' | 'method'
>;

export interface IResult {
  reports: {
    columnHeader: {
      dimensions: string[];
      metricHeader: {
        metricHeaderEntries: {
          name: string;
          type: string;
        }[];
      };
    };
    data: {
      rows?: {
        dimensions: string[];
        metrics: {
          values: string[];
        }[];
      }[];
      totals: {
        values: string[];
      }[];
      rowCount: number;
      minimums: {
        values: string[];
      }[];
      maximums: {
        values: string[];
      }[];
      isDataGolden?: boolean;
    };
    nextPageToken?: string;
  }[];
}
