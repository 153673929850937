import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RepeatIcon from '@material-ui/icons/Repeat';
import React, { memo } from 'react';
import styled from 'styled-components/macro';

import gapi from 'utils/gapi';

export default memo<{ signedIn: boolean }>(({ signedIn }) => {
  const authInstance = gapi.auth2.getAuthInstance();

  return (
    <LoginContainerGrid>
      {signedIn ? (
        <>
          <Typography variant="h6">
            {authInstance.currentUser
              .get()
              .getBasicProfile()
              .getEmail()}
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              authInstance.signOut();
            }}
          >
            ユーザー切り替え <RepeatIcon />
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            authInstance.signIn();
          }}
        >
          Google アカウントでログイン <ExitToAppIcon />
        </Button>
      )}
    </LoginContainerGrid>
  );
});

// #region styles
const LoginContainerGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;

  button {
    margin-left: 8px;
  }
`;
// #endregion
