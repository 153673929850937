import { Paper } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { AppState } from 'reducers';

export default memo<{ setCopyText: (copyText: string) => void }>(
  ({ setCopyText }) => {
    const [selectedQR, setSelectedQR] = useState('');

    let text = '';
    useEffect(() => {
      setCopyText(text);
    });

    const dic = useSelector(({ analytics: { dic } }: AppState) => dic);
    const localeDic = useSelector(
      ({ analytics: { localeDic } }: AppState) => localeDic
    );

    if (_.isEmpty(dic)) {
      return <></>;
    }

    const byQRDic: {
      [qr: string]: {
        [page: string]: {
          page: string;
          pageLink: string;
          total: number;
          viewsByLocale: AppState['analytics']['dic'][0]['viewsByLocale'];
        };
      };
    } = {};

    Object.keys(dic).forEach(date => {
      dic[date].list.forEach(({ page, pageLink, locale, qr, views }) => {
        if (!qr) {
          return;
        }

        byQRDic[qr] = byQRDic[qr] || {};

        const qrDic = byQRDic[qr];
        qrDic[page] = qrDic[page] || {
          page,
          pageLink,
          total: 0,
          viewsByLocale: {}
        };

        qrDic[page].total += views;

        qrDic[page].viewsByLocale[locale] =
          qrDic[page].viewsByLocale[locale] || 0;
        qrDic[page].viewsByLocale[locale] += views;
      });
    });

    let QRTable: JSX.Element | undefined = undefined;

    if (byQRDic[selectedQR]) {
      const pvByPage = _.chain(byQRDic[selectedQR])
        .map(pv => pv)
        .orderBy('total', 'desc')
        .valueOf();

      QRTable = (
        <Paper>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell>ページ</TableCell>
                <TableCell>URL</TableCell>
                {_.map(localeDic, (label, locale) => (
                  <TableCell key={locale}>{label}</TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {pvByPage.map(({ page, pageLink, viewsByLocale }, i) => {
                text += i ? `\n"${page}"` : `"${page}"`;
                text += `\t"${pageLink}"`;
                return (
                  <TableRow key={i}>
                    <TableCell>{page}</TableCell>
                    <TableCell>{pageLink}</TableCell>
                    {_.map(localeDic, (label, locale) => {
                      const views = viewsByLocale[locale] || 0;
                      text += `\t${views}`;
                      return <TableCell key={locale}>{views}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      );
    }

    return (
      <div>
        <br />

        <FormControl style={{ minWidth: 500 }}>
          <InputLabel htmlFor="age-simple">サイネージを選択</InputLabel>
          <Select
            value={selectedQR}
            onChange={({ target: { value } }) => {
              setSelectedQR(value as string);
            }}
            inputProps={{
              name: 'age',
              id: 'age-simple'
            }}
          >
            {_.map(byQRDic, (pages, qr) => (
              <MenuItem key={qr} value={qr}>
                {qr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <br />
        <br />

        {QRTable}
      </div>
    );
  }
);

const StyledTableHead = styled(TableHead)`
  background: #555;
  th {
    color: #ddd !important;
    font-weight: bold;
  }
`;
// #endregion
