import { Grid, Paper } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { GetMplfConfig } from 'apis';
import { Snackbar } from 'components';
import { AppState } from 'reducers';
import { setAnalyticsLocaleDicAction } from 'reducers/analytics';
import gapi, { init } from 'utils/gapi';
import DailyView from './components/DailyView';
import GoogleSignIn from './components/GoogleSignIn';
import PageView from './components/PageView';
import QRView from './components/QRView';
import ViewSelector from './components/ViewSelector';

const AnalyticsScreen = memo(() => {
  const [tabValue, setTabValue] = useState(0);
  const [copyText, setCopyText] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const loading = useSelector(
    ({ analytics: { loading } }: AppState) => loading
  );

  const authInstance = gapi.auth2.getAuthInstance();

  const [signedIn, setSignedIn] = useState(authInstance.isSignedIn.get());

  useEffect(() => {
    authInstance.isSignedIn.listen(setSignedIn);
    return () => authInstance.isSignedIn.listen(() => undefined);
  }, [authInstance.isSignedIn]);

  // get locale settings
  const dispatch = useDispatch();
  useEffect(() => {
    GetMplfConfig().then(result =>
      dispatch(setAnalyticsLocaleDicAction(result))
    );
  }, [dispatch]);

  return (
    <Grid container spacing={1}>
      <GoogleSignIn signedIn={signedIn} />
      {signedIn && <ViewSelector />}
      <Paper style={{ padding: 16, width: '100%' }}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={(e, val) => setTabValue(val)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="日間PV数" />
            <Tab label="ページ別PV数" />
            <Tab label="QRコード読み込み" />
          </Tabs>
        </AppBar>
        {loading ? (
          <div style={{ padding: 8, textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {copyText && (
              <DurationContainerDiv>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    copyToClipboard(copyText);
                    setSnackbarOpen(true);
                  }}
                >
                  データーをコピーする
                </Button>
              </DurationContainerDiv>
            )}
            {tabValue === 0 && <DailyView setCopyText={setCopyText} />}
            {tabValue === 1 && <PageView setCopyText={setCopyText} />}
            {tabValue === 2 && <QRView setCopyText={setCopyText} />}
          </>
        )}
      </Paper>

      <Snackbar
        message="データーをコピーしました"
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      />
    </Grid>
  );
});

export default memo(() => {
  const [gapiLoading, setGapiLoading] = useState(true);

  useEffect(() => {
    init().then(() => {
      setGapiLoading(false);
    });
  }, []);

  return gapiLoading ? (
    <CircularProgress style={{ margin: '0 auto' }} />
  ) : (
    <AnalyticsScreen />
  );
});

// #region styles
const DurationContainerDiv = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;

  p {
    margin-right: 16px;
    background: #888;
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: bold;
    color: white;
    font-size: 15px;
  }
`;
// #endregion

const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document

  // Check if there is any content selected previously
  let selected: boolean | Range = false; // Mark as false to know no selection existed before
  const selection = document.getSelection();
  if (selection && selection.rangeCount) {
    selected = selection.getRangeAt(0); // Store selection if found
  }
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected && selection) {
    // If a selection existed before copying
    selection.removeAllRanges(); // Unselect everything on the HTML document
    selection.addRange(selected); // Restore the original selection
  }
};
